<template>
  <section
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
  >
    <div class="browser-wrapper" v-if="currentWidth >= 1280">
      <h3>{{ $t('menu.depositFund') }} (Special Page) ＞＞＞</h3>
      <div class="selection-box">
        <el-tabs
          :stretch="true"
          :class="{ smail_title: ['es', 'pt'].includes(lang) }"
          type="card"
          v-model="selectedTab"
        >
          <el-tab-pane
            v-for="(tab, index) in Object.values(TABS_ITEMS)"
            :key="index"
            :label="tab.title"
            :name="tab.name"
          >
            <component :is="TABS_ITEMS[selectedTab].component" :cpsDepositList="cpsDepositList"></component>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- mobile -->
    <div class="mobile-wrapper" v-else>
      <h3>{{ $t('menu.depositFund') }} (Special Page) >>></h3>
      <div class="mobile-title">{{ TABS_ITEMS[selectedTab].title }}</div>
      <ul class="mobile-box">
        <li
          v-for="(mobileTab, idx) in Object.values(TABS_ITEMS)"
          :key="idx"
          :class="{ isActive: mobileTab.name === selectedTab }"
          @click="selectedTab = mobileTab.name"
        >
          <img
            :src="
              require(`@/assets/images/deposit/${mobileTab.img}${mobileTab.name === selectedTab ? '_active' : ''}.png`)
            "
            :alt="mobileTab.name"
            :data-testid="mobileTab.name"
          />
        </li>
      </ul>

      <div class="mobile-top">
        <component :is="TABS_ITEMS[selectedTab].component" :cpsDepositList="cpsDepositList" />
      </div>
    </div>
  </section>
</template>

<script>
import cpsDepositListingMixin from '@/mixins/page/deposit/cpsDepositListing';

export default {
  name: 'FsaDepositCps',
  mixins: [cpsDepositListingMixin],
  data() {
    return {
      selectedTab: 'cryptoCurrency',
      TABS_ITEMS: {
        cryptoCurrency: {
          component: () =>
            this.$config.componentLoader(this.regulator, 'deposit/cryptoCurrency/depositListCps').component,
          title: this.$t('common.withdrawChannel.CRYPTOCURRENCY'),
          img: `crypto_mobile`,
          name: `cryptoCurrency`
        },
        creditCard: {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/creditCard/depositListCps').component,
          title: this.$t('common.withdrawChannel.creditcard'),
          img: `cc_mobile`,
          name: `creditCard`
        },
        eWallet: {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/eWallet/depositListCps').component,
          title: this.$t('common.withdrawChannel.E_WALLET'),
          img: `ew_mobile`,
          name: `eWallet`
        },
        localTransfer: {
          component: () =>
            this.$config.componentLoader(this.regulator, 'deposit/localTransfer/depositListCps').component,
          title: this.$t('common.withdrawChannel.LOCAL_TRANSFER'),
          img: `lt_mobile`,
          name: `localTransfer`
        },
        bankTransfer: {
          component: () => this.$config.componentLoader(this.regulator, 'deposit/bankTransfer/depositList').component,
          title: this.$t('common.withdrawChannel.banktransfer'),
          img: `bank_mobile`,
          name: `bankTransfer`
        }
      },
      currentWidth: 0,
      cpsDepositList: []
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  mounted() {
    // get cps deposit channels list
    this.getCPSDepositChannels(true);
  },
  methods: {
    observerCallBack(width) {
      this.currentWidth = width;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .smail_title {
  #tab-bankTransfer {
    font-size: 12px !important;
  }
}
</style>
